const React = require('react');
const PropTypes = require('prop-types');
const CarouselSnapped = require('@andes/carousel-snapped');
const { CarouselSnappedSlide } = require('@andes/carousel-snapped');
const Image = require('../../../commons/image');
const Section = require('../../../commons/section');
const withTracker = require('../../../commons/with-tracker');
const Banner = require('./banner');

function ExhibitorItem({ permalink, picture, title }) {
  return (
    <a className="exhibitor-item" href={permalink}>
      <div className="exhibitor-holder">
        <Image {...picture} alt={title} />
      </div>
    </a>
  );
}

ExhibitorItem.propTypes = {
  permalink: PropTypes.string.isRequired,
  picture: PropTypes.shape(Image.propTypes).isRequired,
  title: PropTypes.string,
};

ExhibitorItem.defaultProps = {
  title: null,
};

function Exhibitor({ items, deviceType, type, lowEnd, showTopBanner }) {
  if(items.length < 1) return null;
  const slides = items.map((element, id) => ({ ...element, id: `${id}` }));
  return (
    <Section type={type}>
      {showTopBanner && (
        <a className="banner-re__container" href={Banner.url} aria-label={Banner.title}>
          <div className="banner-re__img" />
        </a>
      )}
      {slides.length === 1 ? (
        <div className="carousel-container">
          <ExhibitorItem {...slides[0]} />
        </div>
      ) : (
        <CarouselSnapped
          srLabel="carousel"
          autoplay={deviceType === 'desktop'}
          arrows={deviceType === 'desktop' ? {
            size: "large",
            visibility: 'hover',
          } : false}
          speed={deviceType === 'mobile' ? 275 : 500}
          infinite
          pagination={!lowEnd && {
            mode: 'dark',
            position: 'bottom',
          }}
          strictBoundaries={false}
        >
          {slides.map(item => (
            <CarouselSnappedSlide key={item.id}>
              <ExhibitorItem {...item} />
            </CarouselSnappedSlide>
          ))}
        </CarouselSnapped>
      )}
    </Section>
  );
}

Exhibitor.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  type: PropTypes.string.isRequired,
  showTopBanner: PropTypes.bool,
  lowEnd: PropTypes.bool.isRequired,
  deviceType: PropTypes.oneOf(['desktop', 'mobile', 'tablet']).isRequired,
};

module.exports = withTracker(Exhibitor);
