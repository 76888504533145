const React = require('react');
const PropTypes = require('prop-types');
const ComponentList = require('../../commons/component-list');
const Wrapper = require('../wrapper');

const Home = ({ children, ...props }) => {

  const {
    // commons
    platformId, siteId, deviceType, meliLab, userAgent,
    // locals
    melidata, atf, hotjar, newrelicTransactionName,
    currentSearch, preloadRecommendationsImgs,
    // request
    lowEnd, url, user, userId, currentUser, platform, device, locale,
    csrfToken, isLab, isFirstVisit, translations,
    // config
    mainDomain, baseDomain, defaultTitle, home, baseURL,
    // internal
    criticalPath, isServerSide,
    // custom
    ssrComponents, availableComponents, experiments,
    recaptchaEntKey,
    showTopBanner,
  } = props;
  const serializedProps = {
    platformId, siteId, deviceType, meliLab, userAgent,
    melidata, atf, hotjar, newrelicTransactionName,
    currentSearch, preloadRecommendationsImgs,
    lowEnd, url, user, userId, currentUser, platform,
    csrfToken, isLab, isFirstVisit, translations,
    mainDomain, baseDomain, defaultTitle, home, baseURL,
    criticalPath, isServerSide,
    ssrComponents, availableComponents, experiments, device, locale,
    recaptchaEntKey,
    showTopBanner,
  };

  const forwardProps = {
    metadata: atf.metadata,
    // serialized
    ...serializedProps,
  };

  const list = ssrComponents || atf.components;
  return (
    <Wrapper {...serializedProps} pageId="HOME" pageName="home" innerClassName="home">
      {children}
      <ComponentList availableComponents={availableComponents} list={list} {...forwardProps} />
    </Wrapper>
  );
};


Home.propTypes = {
  ...Wrapper.propTypes,
  // forward
  meliLab: PropTypes.string,
  currentUser: PropTypes.object,
  isServerSide: PropTypes.bool,
  // custom
  ssrComponents: PropTypes.array,
  availableComponents: PropTypes.object,
};

Home.defaultProps = {
  ...Wrapper.defaultProps,
  meliLab: '',
  currentUser: null,
  isServerSide: false,
  ssrComponents: null,
  availableComponents: null,
};

module.exports = Home;
