const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames'); // eslint-disable-line import/no-extraneous-dependencies
const Image = require('../image');
const global = require('../../../global');
const itemHelper = require('./helper');
const withTracker = require('../with-tracker');
const restClient = require('../../../../client/services/rest-client');

const metadataPropTypes = PropTypes.shape({
  decimal_places: PropTypes.number.isRequired,
  decimal_separator: PropTypes.string.isRequired,
  thousand_separator: PropTypes.string.isRequired,
});

function withItemBehavior(WrappedComponent) {
  class WithItemBehavior extends React.Component {
    constructor(props) {
      super(props);
      const { decimal_places, thousand_separator } = props.metadata;
      const discount = itemHelper.getDiscount(props, decimal_places, thousand_separator);
      const className = classNames(props.className, {
        'with-price-context-message': props.price_context_message,
      });

      this.bookmark = this.bookmark.bind(this);
      this.restorePreviousBookmark = this.restorePreviousBookmark.bind(this);
      this.onFinishFetching = this.onFinishFetching.bind(this);
      this.switchBookmark = this.switchBookmark.bind(this);
      this.itemHover = this.itemHover.bind(this);
      this.isFetching = false;
      this.mappedProps = {
        ...props,
        className,
        discount,
        showTitle: props.squared,
        image: props.picture,
        // props.attributes are handle by /ui-item/item.
        price: {
          ...props.price,
          symbol: props.price.currency_symbol,
          fraction: itemHelper.getFormattedFraction(props.price.value, thousand_separator),
          cents: itemHelper.getDecimalPart(props.price.value, decimal_places),
          decimal_separator: props.metadata.decimal_separator, // Not displayed, but needed for microdata
          font_size_price: props.deviceType === 'mobile' ? 20 : 24,
        },
        shipping: props.shipping ? itemHelper.getShipping(props.shipping) : null,
        url: props.permalink,
        onFavClick: this.bookmark,
      };

      this.state = {
        bookmarked: props.bookmarked,
        singleLineTitleClassName: '',
      };
    }

    onFinishFetching() {
      this.isFetching = false;
    }

    switchBookmark(prevState) {
      return {
        bookmarked: !prevState.bookmarked,
      };
    }

    bookmark(e) {
      e.preventDefault();
      e.stopPropagation();

      if (!this.isFetching) {
        this.isFetching = true;
        this.setState(this.switchBookmark, this.updateBookmark);
      }
    }

    updateBookmark() {
      const { id } = this.props;
      const { bookmarked } = this.state;
      // eslint-disable-next-line
      const verb = bookmarked ? 'post' : 'delete';
      const goUrl = `${global.location.origin}/pi/home/api/bookmarks/${id}?go=${global.location.href}`;
      restClient[verb](`/bookmarks/${id}`, {
        loginParams: {
          loginType: 'FAVORITE',
          item_id: id,
          go: goUrl,
        },
        params: { go: `${global.location.href}` },
      })
        .then(this.onFinishFetching)
        // Restore the previous bookmark state if the endpoint failed.
        .catch(this.restorePreviousBookmark);
    }

    restorePreviousBookmark() {
      this.setState(this.switchBookmark, this.onFinishFetching);
    }

    itemHover() {
      if (this.title) {
        const itemDescriptionHeight = this.title.clientHeight;
        this.isSingleLineDescription(itemDescriptionHeight);
      }
    }

    isSingleLineDescription(height) {
      if (height <= 18) {
        this.setState({
          singleLineTitleClassName: 'with-single-line-description',
        });
      }
    }

    render() {
      const { bookmarked, singleLineTitleClassName } = this.state;
      this.mappedProps.bookmarked = bookmarked;
      const className = classNames(this.mappedProps.className, singleLineTitleClassName);
      const wrapperClassNames = className && className.split(/\s+/).map(name => `${name}__wrapper`);
      return (
        <div
          className={classNames('ui-item__wrapper', wrapperClassNames)}
          onMouseOver={this.itemHover}
          onFocus={this.itemHover}
        >
          <WrappedComponent
            {...this.mappedProps}
            className={className}
            titleRef={(title) => { this.title = title; }}
          />
        </div>
      );
    }
  }

  WithItemBehavior.itemPropTypes = {
    actions: PropTypes.array,
    bookmarked: PropTypes.bool,
    className: PropTypes.string,
    // id: PropTypes.string.isRequired,
    is_trigger: PropTypes.bool,
    permalink: PropTypes.string.isRequired,
    picture: PropTypes.shape(Image.propTypes).isRequired,
    price: PropTypes.shape({
      currency_id: PropTypes.string,
      currency_symbol: PropTypes.string,
      text: PropTypes.string,
      value: PropTypes.number,
    }).isRequired,
    squared: PropTypes.bool,
  };

  WithItemBehavior.defaultProps = {
    actions: [],
    bookmarked: false,
    className: '',
    currency_id: null,
    currency_symbol: null,
    price: null,
    price_text: null,
    is_trigger: false,
    squared: false,
  };

  WithItemBehavior.propTypes = {
    ...WithItemBehavior.itemPropTypes,
    metadata: metadataPropTypes.isRequired,
  };

  const exportItem = withTracker(WithItemBehavior);
  exportItem.metadataPropTypes = metadataPropTypes;
  return exportItem;
}

module.exports = withItemBehavior;
