const React = require('react');
const PropTypes = require('prop-types');
const { Image } = require('nordic/image');

const CustomImage = ({ src, src2x, lazyLoad, ...imageProps }) => (
  <Image
    srcSet={src2x ? `${src} 1x, ${src2x} 2x` : null}
    src={src}
    lazyload={lazyLoad ? 'on' : 'off'}
    {...imageProps}
  />
);

CustomImage.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  lazyLoad: PropTypes.bool,
  src: PropTypes.string.isRequired,
  src2x: PropTypes.string,
  size: PropTypes.string,
};

CustomImage.defaultProps = {
  alt: null,
  className: null,
  lazyLoad: true,
  src2x: null,
  size: null,
};

module.exports = CustomImage;
