const React = require('react');
const PropTypes = require('prop-types');
const CarouselSnapped = require('@andes/carousel-snapped');
const { CarouselSnappedSlide } = require('@andes/carousel-snapped');
const RecommendationsContainer = require('./recommendations-container');
const desktopSeeds = require('./seeds/desktop');
const Item = require('../item');
const withTracker = require('../with-tracker');
const withTrackerOnPrint = require('../with-tracker-on-print');

const Recommendations = (props) => {
  const { items, lazyLoad, metadata, id, seed_info: seedInfo, deviceType } = props;
  const imgSize = '224';
  const extendedItems = items.map(item => ({
    ...item,
    lazyLoad,
    metadata,
    imgSize,
    deviceType,
  }));
  const Seed = desktopSeeds[id];
  if (Seed) {
    extendedItems.unshift({
      Component: Seed,
      id: `seed-${id}`,
      ...seedInfo,
    });
  }
  if(extendedItems.length < 1 ) return null;
  return (
    <RecommendationsContainer {...props}>
      <CarouselSnapped
        strictBoundaries={false}
        spacing={0}
        pagination={false}
        srLabel="carousel"
        arrows={{
          size: "large",
          visibility : "hover",
        }}
        infinte={false}
        slidesPerView={5}
      >
        {extendedItems.map((item) => (
          <CarouselSnappedSlide key={item.id}>
            <Item {...item} />
          </CarouselSnappedSlide>
        ))}

      </CarouselSnapped>
    </RecommendationsContainer>
  );
};

Recommendations.propTypes = {
  carousel: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape(Item.itemPropTypes)).isRequired,
  lazyLoad: PropTypes.bool.isRequired,
  metadata: Item.metadataPropTypes.isRequired,
  seed_info: PropTypes.object,
  deviceType: PropTypes.oneOf(['desktop', 'mobile', 'tablet']).isRequired,
};

Recommendations.defaultProps = {
  seed_info: null,
};

module.exports = withTracker(withTrackerOnPrint(Recommendations));
