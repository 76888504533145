const logger = require('nordic/logger');
const React = require('react');
const PropTypes = require('prop-types');
const Textfield = require('@andes/textfield');
const Button = require('@andes/button');
const Snackbar = require('@andes/snackbar');
const Section = require('../../../../commons/section');
const { trackFindByCodeEvent } = require('../utils/events');
const restClient = require('../../../../../../client/services/rest-client');

const log = logger('services/landing');
const recaptchaAction = 'search_by_code';
const namespace = 'search-by-code';

const ENTER = 13;
function SearchBar(props) {

  const { state, handleChange, callApiSearch, children, onKeyPress } = props;

  return (
    <Section className={`${namespace}__container`}>
      <Section className={`${namespace}__box`}>
        <Textfield
          className={`${namespace}__textfield`}
          onChange={handleChange}
          onKeyPress={onKeyPress}
          name="code"
          value={state.code}
          placeholder='Busca una propiedad por código'
          autoFocus
        />
        <Button onClick={callApiSearch}>Buscar</Button>
      </Section>
      {children}
      {state.error ? (
        <Snackbar
          message={state.error}
          color="red"
          show
          delay={3000}
        />
      )
        : null}
    </Section>
  );
};

class SearchByCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: '',
      error: '',
      recaptchaToken: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.callApiSearch = this.callApiSearch.bind(this);
    this.keyPress = this.keyPress.bind(this);
  }

  componentDidMount() {
    this.recaptchaToken();
  }

  onSent(response) {
    if (response.status === 200 && response.url !== '' && document && window) {
      window.location = response.url;
    } else {
      this.setState({ error: response.error || '' });
    }
  }

  callApiSearch() {
    let { code } = this.state;

    if (code) {
      this.setState({ error: '' });

      if (code.trim() !== "") {
        code = code.trim();
        code = encodeURIComponent(code);
        // eslint-disable-next-line no-underscore-dangle
        const { recaptchaToken } = this.state;
        restClient.get(`/search/code/${code}?recaptchaToken=${recaptchaToken}`)
          .then((response) => {
            this.clickTrack();
            const responseApi = response.data ? response.data : response;
            if (responseApi.status !== 200) {
              responseApi.error = 'El código ingresado no pertenece a una propiedad';
            }
            this.onSent(responseApi);
          })
          .catch();
      }
    }
  }

  /* global grecaptcha */
  recaptchaToken() {
    const { recaptchaEntKey } = this.props;
    try {
      grecaptcha.enterprise.ready(() => {
        grecaptcha.enterprise.execute(recaptchaEntKey, { action: recaptchaAction }).then((token) => {
          this.setState({ recaptchaToken: token });
        });
      });
    } catch (err) {
      log.error(err, { stack: err.stack });
    }
  }

  handleChange(event) {
    const { target } = event;
    const { name, value } = target;

    this.setState({
      [name]: value,
    });
  }

  clickTrack() {
    trackFindByCodeEvent();
  }

  keyPress(e) {
    const key = e.keyCode || e.which || e.charCode;
    if (key === ENTER) {
      this.callApiSearch();
    }
  }


  render() {
    const { children, recaptchaEntKey } = this.props;
    const { code, error, recaptchaToken } = this.state;

    return (
      <SearchBar
        handleChange={this.handleChange}
        callApiSearch={this.callApiSearch}
        onKeyPress={this.keyPress}
        recaptchaEntKey={recaptchaEntKey}
        state={{ code, error, recaptchaToken }}
      >
        {children}
      </SearchBar>
    );
  }
};

SearchBar.propTypes = {
  state: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  callApiSearch: PropTypes.func.isRequired,
};

SearchByCode.propTypes = {
};

module.exports = SearchByCode;
