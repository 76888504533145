const COOKIES = {
  FACETED_SEARCH: 'faceted-search',
  SAVED_SEARCHES_TUTORIAL: 'ml_faceted-search_saved-searches-tutorial',
};

const FURY_CONFIG = {
  name: 'application',
  topBanner: 'top-banner',
};

module.exports = {
  COOKIES,
  FURY_CONFIG,
};
