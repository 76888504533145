const React = require('react');
const PropTypes = require('prop-types');
const Image = require('../../commons/image');
const withLifeCycle = require('../with-life-cycle/desktop');

const ImageDesktop = ({ src, ...props }) => (
  <div className="ui-item__image-container">
    <meta itemProp="image" content={src} />
    <Image className="ui-item__image" src={src} {...props} />
  </div>
);

ImageDesktop.propTypes = {
  mounted: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/require-default-props
  lazyLoad: PropTypes.bool,
  src: PropTypes.string.isRequired,
  size: PropTypes.string,
  src2x: PropTypes.string,
  title: PropTypes.string,
};

ImageDesktop.defaultProps = {
  size: null,
  src2x: null,
  title: null,
};

module.exports = withLifeCycle(ImageDesktop);
